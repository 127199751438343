
class SorterService {
    
    onToday(meetings) {
        function time_to_numeric(time) {
            const [_, h, m] = time.match(/(\d+):(\d+)/);
            return parseInt(h) * 60 + parseInt(m);
          }
        const now = new Date();
        const today = now.getDay();
        const now_numeric = now.getHours() * 60 + now.getMinutes();
        //console.log("now_numeric:",now_numeric)
        let onToday = [];
        for (let i = 0; i < meetings.length; i++) {
            const meeting_numeric = time_to_numeric(meetings[i].hour);
            //console.log(meetings[i].name,meeting_numeric);
            if(meetings[i].day == today && meeting_numeric >= now_numeric) {
                onToday.push(meetings[i]);
            }
        }
        return onToday;
    }
    /*
    timeSort(meetings) {
        function time_to_numeric(time) {
            const [_, h, m] = time.match(/(\d+):(\d+)/);
            let [hours, min] = [parseInt(h), parseInt(m)];
            return hours * 60 + min;
        }
        const now = new Date();
        const now_numeric = now.getHours() * 60 + now.getMinutes();
        const MINUTES_PER_DAY = 24 * 60;
        const closer_times_by_id = meetings.reduce((meetings, {id, hour, day, place, name}) => {
            const time_numeric = time_to_numeric(hour);
            let diff = time_numeric - now_numeric;
            const prev_diff = meetings[id] && meetings[id].diff;
            if (diff < 0) diff = time_numeric + MINUTES_PER_DAY - now_numeric;
            if (prev_diff === undefined || diff < prev_diff) {
                meetings[id] = { diff, hour, day, place, name };
            }
            return meetings;
        }, {});
        return Object.entries(closer_times_by_id).map(item => {
            const [id, { hour, day, place, name }] = item;
            return { id, hour, day, place, name };
        });
    }
    */
    timeSort(meetings) {
        function time_to_numeric(time) {
          const [_, h, m] = time.match(/(\d+):(\d+)/);
          return parseInt(h) * 60 + parseInt(m);
        }
      
        const now = new Date();
        const now_numeric = now.getHours() * 60 + now.getMinutes();
      
        return meetings.sort((a, b) => {
          const diffA = (time_to_numeric(a.hour) - now_numeric + 1440) % 1440; // Ensure positive difference
          const diffB = (time_to_numeric(b.hour) - now_numeric + 1440) % 1440; // Ensure positive difference
          return diffA - diffB;
        });
    }
}
export default new SorterService();