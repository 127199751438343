import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import dataService from '../services/data.service';
import sorterService from '../services/sorter.service';

export default class Profile extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          redirect: null,
          userReady: false,
          name: "",
          time: "",
          place: "",
          ticker: "",
          counter: 0,
	  mapsURL: "https://www.google.com/maps/search/?api=1&query=",
	  daily: ""
        };
      }
    
    async meetings() {
        const meetings = await dataService.getMeetings();
        const onToday = sorterService.onToday(meetings.data);
        const sorted = sorterService.timeSort(onToday);
        return sorted;
    }

    daily() {
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth().toString(); // January is 0
	const day = currentDate.getDate().toString();
	switch (month) {
		case "0":
			return "Tammikuun" + day + ".txt"
		case "1":
			return "Helmikuun" + day + ".txt"
		case "2":
			return "Maaliskuun" + day + ".txt"
		case "3":
			return "Huhtikuun" + day + ".txt"
		case "4":
			return "Toukokuun" + day + ".txt"
		case "5":
			return "Kesäkuun" + day + ".txt"
		case "6":
			return "Heinäkuun" + day + ".txt"
		case "7":
			return "Elokuun" + day + ".txt"
		case "8":
			return "Syyskuun" + day + ".txt"
		case "9":
			return "Lokakuun" + day + ".txt"
		case "10":
			return "Marraskuun" + day + ".txt"
		case "11":
			return "Joulukuun" + day + ".txt";
		default:
			return "something went wrong"
	}
    }

    async componentDidMount() {
        /*
        dataService.getMeetings().then(
            response => {
                this.setState({
                    ticker: JSON.stringify(sorterService.timeSort(sorterService.onToday(response.data)))
                })
            }, error => {
                this.setState({ ticker: JSON.stringify(error.response.data.message)})
            }
        )
        */
	try {
		console.log(this.daily());
	 	const response = await fetch(this.daily());
	        const content = await response.text();
		const formattedContent = content.replace(/\n/g, '<br>');
	        this.setState({ daily: formattedContent });
	  } catch (error) {
		console.error('Error loading file:', error);
	  }

        const now = new Date();
        const currentHour = now.getHours();
        const meetings = await this.meetings();
	let lastMeeting;
	let lastMeetingHour;
	try {
        	lastMeeting = meetings[meetings.length-1].hour;
        	lastMeetingHour = parseInt(lastMeeting.match(/(\d+):/));
	} catch {
		lastMeetingHour = 1;
	}
        if(lastMeetingHour >= currentHour) {
            this.setState({
                time: meetings[this.state.counter].hour,
                name: meetings[this.state.counter].name,
                place: meetings[this.state.counter].place,
		mapsURL: "https://www.google.com/maps/search/?api=1&query=" + meetings[this.state.counter].place.replaceAll(" ","+") + "%2C+Tampere"
            });
        }
        else {
            this.setState({
                time: "Ei ole enää ryhmiä tänään, mene nukkumaan",
                name: "",
                place: ""
            });
        }
    }

    async increment() {
        const meetings = await this.meetings();
        if(meetings[this.state.counter + 1] !== undefined) {
            this.setState((prevState) => ({
                counter: prevState.counter + 1,
                time: meetings[prevState.counter + 1].hour,
                name: meetings[prevState.counter + 1].name,
                place: meetings[prevState.counter + 1].place,
		mapsURL: "https://www.google.com/maps/search/?api=1&query=" + meetings[prevState.counter + 1].place.replaceAll(" ","+") + "%2C+Tampere"
            }));
        }
        else {
            this.setState({
                time: "Ei ole enää ryhmiä tänään, mene nukkumaan",
                name: "",
                place: ""
            });
        }
    }

    render() {
        if (this.state.redirect) {
          return <Navigate to={this.state.redirect} />
        }
    return(
        <>
            <div className='App-form'>
                <div className='time'>
                <b>{this.state.time}</b>
                </div>
                <div>
                Nimi: <b>{this.state.name}</b>
                </div>
                <div>
                Paikka: <b>{this.state.place}</b>
                </div>
	    	<div>
                <a href={this.state.mapsURL}>Kartalla</a>
                </div>
                <div>
                <button className='btn' onClick={() => this.increment()}>En kerkeä, näytä seuraava</button>
                </div>
                <div>
                {this.state.ticker}
                </div>
	    	<div>
		<br />
	          {this.state.daily && (
			          <div dangerouslySetInnerHTML={{ __html: this.state.daily }} />
			        )}
	        </div>
            </div>
        </>
    )
}}
