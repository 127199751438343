import axios from 'axios';
const API_URL = "https://kuntoutus.info/meetings/";

class DataService {
    getMeetings = async() => {
        return axios.get(API_URL).then(response => {
            return response.data;
        })
    }
}
export default new DataService();
